import request from '@/utils/scm-request'
import requestWms from '@/utils/request'
// 分页查询
export function getAppointOrder(data, pager) {
  return request({
    url: `scm-purchase/appointOrder/page?size=${pager.size}&current=${pager.current}`,
    method: 'post',
    data
  })
}
// 作废
export function invalidateAppointOrder(data) {
  return request({
    url: `scm-purchase/appointOrder/invalidate`,
    method: 'post',
    data
  })
}
// 撤销
export function cancelAppointOrder(data) {
  return request({
    url: `scm-purchase/appointOrder/cancel`,
    method: 'post',
    data
  })
}
// 确认预约
export function confirmAppointOrder(data) {
  return request({
    url: `scm-purchase/appointOrder/confirm`,
    method: 'post',
    data
  })
}
// 新增
export function insertAppointOrder(data) {
  return request({
    url: `scm-purchase/appointOrder/insert`,
    method: 'post',
    data
  })
}
// 查看预约单
export function getAppointOrderinfo(id) {
  return request({
    url: `scm-purchase/appointOrder/info/${id}`,
    method: 'get'
  })
}
// 修改预览
export function editAppointOrderinfo(id) {
  return request({
    url: `scm-purchase/appointOrder/modifyView/${id}`,
    method: 'get'
  })
}
// 修改提交
export function editAppointOrder(data) {
  return request({
    url: `scm-purchase/appointOrder/modifySubmit`,
    method: 'post',
    data
  })
}
// IMS仓库name
export function getImsWarehouse(data) {
  return request({
    url: `scm-purchase/basic/warehouseBasic/getWarehouse`,
    method: 'post',
    data
  })
}
// IMS仓库地址
export function getwarehouseAddressList(data) {
  return request({
    url: `scm-purchase/basic/warehouseBasic/warehouseAddressList/${data}`,
    method: 'post',
    data
  })
}
// 供应商下拉框
export function getNewVendorList(data) {
  return request({
    url: `scm-purchase/basic/vendorBasic/vendorList`,
    method: 'post',
    data
  })
}
// 供应商地址下拉框
export function getVendorAddressList(data) {
  return request({
    url: `scm-purchase/basic/vendorBasic/vendorAddressList`,
    method: 'post',
    data
  })
}
// 退货单号下拉框
export function getReturnPoCodeList(params) {
  return request({
    url: `scm-purchase/basic/returnOrderBasic/poCodeListByEffectiveStatusAndVendorId/${params}`,
    method: 'get'
  })
}
// po单号下拉框
export function getPoCodeList(params) {
  return request({
    url: `scm-purchase/basic/purchaseOrderBasic/poCodeListByEffectiveStatusAndVendorId/${params}`,
    method: 'get'
  })
}
// 导出
export function appointOrderExport(data) {
  return request({
    url: `/scm-purchase/appointOrder/export`,
    method: 'post',
    data
  })
}
// 导出明细
export function appointOrderExportDetail(data) {
  return request({
    url: `/scm-purchase/appointOrder/exportDetail`,
    method: 'post',
    data
  })
}
// style获取一级类别
export function getCategory1List(data) {
  return request({
    url: `/scm-purchase/basic/styleBasic/styleCategory1List`,
    method: 'post',
    data
  })
}
// 根据style\color\size获取sku
export function getSkuInfo(data) {
  return request({
    url: `/scm-purchase/basic/skuBasic/skuListByStyleColorSize`,
    method: 'post',
    data
  })
}
// Style信息查询
export function queryStyleList(params) {
  return requestWms({
    url: `/base/basestyle/list`,
    method: 'get',
    params
  })
}
// 供应商详细地址下拉框
export function getVendorAddressDetailList(data) {
  return request({
    url: `scm-purchase/basic/vendorBasic/detail/vendorAddress`,
    method: 'post',
    data
  })
}

// 导入-模板下载
export function downloadTemplateImport(data) {
  return request({
    url: `scm-purchase/appointOrder/import/downloadTemplate`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}

// 导入-预览
export function previewImport(data) {
  return request({
    url: `scm-purchase/appointOrder/import/preview`,
    method: 'post',
    data
  })
}

// 导入-预览下载异常信息
export function DownloadAbnormalData(data) {
  return request({
    url: `scm-purchase/appointOrder/download/abnormalData`,
    method: 'post',
    data
  })
}

// 导入-提交
export function submitImport(data) {
  return request({
    url: `scm-purchase/appointOrder/import/submit`,
    method: 'post',
    data
  })
}
