<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">基本信息</el-col>
    </el-row>

    <el-form ref="Form" :model="supplierform" label-width="120px">
      <el-row :span="24">
        <el-col :span="6">
          <el-form-item label="预约单号" prop="appointOrderCode">
            <el-input v-model="supplierform.appointOrderCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="关联单号" prop="relationCode">
            <el-input v-model="supplierform.relationCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态" prop="stateDict">
            <el-select v-model="supplierform.stateDict" :placeholder="$t('page.selectPlaceholder')" disabled style="width: 100%;">
              <el-option
                v-for="item in _getAllCommodityDict('SCM_APPOINT_ORDER_STATE')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运输类型" prop="transportTypeDict" :rules="defaultRules">
            <el-select v-model="supplierform.transportTypeDict" style="width: 100%;" :placeholder="$t('page.selectPlaceholder')" :disabled="type==='view'" @change="getTransType">
              <el-option
                v-for="item in _getAllCommodityDict('SCM_APPOINT_TRANSPORT_TYPE')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="始发地" prop="originPlaceId" :rules="defaultRules">
            <el-select
              v-model="supplierform.originPlaceId"
              value-key="id"
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="type==='view'"
              style="width: 100%;"
              @change="handleOrigin"
            >
              <el-option v-for="item in originOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="始发地地址" prop="originPlaceAddress" :rules="defaultRules">
            <el-select
              v-model="supplierform.originPlaceAddress"
              value-key="id"
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="originAddressOptions.length<=1||type==='view'"
              popper-class="my-select"
              style="width: 100%;"
              @change="getOrgin"
              @focus="setOptionWidth"
            >
              <el-option v-for="(item,index) in originAddressOptions" :key="index" :style="{width: selectOptionWidth}" :label="item.address" :value="item.address" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="目的地" prop="destinationId" :rules="defaultRules">
            <el-select
              v-model="supplierform.destinationId"
              value-key="id"
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="type==='view'"
              style="width: 100%;"
              @change="handleDestination"
            >
              <el-option v-for="item in destinationOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="目的地地址" prop="destinationAddress" :rules="defaultRules">
            <el-select
              v-model="supplierform.destinationAddress"
              value-key="id"
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="destinationAddressOptions.length<=1||type==='view'"
              popper-class="my-select"
              style="width: 100%;"
              @change="getDestination"
              @focus="setOptionWidth"
            >
              <el-option v-for="(item,index) in destinationAddressOptions" :key="index" :style="{width: selectOptionWidth}" :label="item.address" :value="item.address" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="提货联系人" prop="pickupContactPerson" :rules="defaultRules">
            <el-input v-model="supplierform.pickupContactPerson" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="提货联系电话" prop="pickupContactNumber" :rules="defaultRules">
            <el-input v-model="supplierform.pickupContactNumber" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="送货方式" prop="deliveryMethodDict" :rules="defaultRules">
            <el-select v-model="supplierform.deliveryMethodDict" style="width: 100%;" :placeholder="$t('page.selectPlaceholder')" :disabled="type==='view'">
              <el-option
                v-for="item in _getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="预计发货时间" prop="deliveryDate" label-width="150px" :rules="defaultRules">
            <el-date-picker
              v-model="supplierform.deliveryDate"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="type==='view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label-width="0px" prop="deliveryTime" :rules="defaultRules">
            <el-time-select
              v-model="supplierform.deliveryTime"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间"
              :disabled="type==='view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="预计到货时间" prop="dilatDate" label-width="150px" :rules="defaultRules">
            <el-date-picker
              v-model="supplierform.dilatDate"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="type==='view'"
            />
          </el-form-item>

        </el-col>
        <el-col :span="3">
          <el-form-item label-width="0px" prop="dilatTime" :rules="defaultRules">
            <el-time-select
              v-model="supplierform.dilatTime"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间"
              :disabled="type==='view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="supplierform.remark"
              type="textarea"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="100"
              show-word-limit
              :disabled="type==='view'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <el-col :span="12">
        <span style="padding-left:25px">预约运输明细</span>
      </el-col>
      <el-col :span="12" style="color:#333;font-size:16px;display:flex">
        <div style="width:25%">总数量：{{ totalQuantity }}</div>
        <div style="width:25%">总箱数：{{ totalBoxCount }}</div>
        <div style="width:25%">总重量：{{ totalWeight.toFixed(2) }}</div>
        <div style="width:25%">总体积：{{ totalVolume.toFixed(2) }}</div>
      </el-col>
    </el-row>
    <el-skeleton :loading="loading" animated :count="1">
      <template>
        <div v-if="detailDatas.length">
          <vxe-table
            ref="xTable"
            border
            resizable
            class="mb-3"
            max-height="600px"
            show-footer
            :footer-method="footerMethod"
            :data="detailDatas"
            :edit-rules="detailRules"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-column type="seq" width="60" title="序号" />
            <vxe-column field="vendorId" title="供应商" :edit-render="{}" width="100">
              <template #default="{row}">
                {{ vendorOptions.find(item=>item.id===row.vendorId).vendorName||'' }}
              </template>
              <template #edit="{ row }">
                <vxe-select
                  v-model="row.vendorId"
                  :placeholder="$t('page.selectPlaceholder')"
                  disabled
                  transfer
                >
                  <vxe-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column
              field="sourceCode"
              :title="supplierform.transportTypeDict === 'PURCHASE_RETURN'?'采购退货单号':'采购订单号'"
              :edit-render="{}"
              width="180"
            >
              <template #default="{ row }">
                <el-select
                  v-model="row.sourceCode"
                  clearable
                  filterable
                  :disabled="type==='view'"
                  transfer
                >
                  <el-option v-for="item in codeOptions" :key="item" :label="item" :value="item" />
                </el-select>
              </template>
              <template #edit="{ row }">
                <el-select
                  v-model="row.sourceCode"
                  clearable
                  filterable
                  transfer
                  :disabled="type==='view'"
                >
                  <el-option v-for="item in codeOptions" :key="item" :label="item" :value="item" />
                </el-select>
              </template>
            </vxe-column>
            <vxe-column field="categoryName1" title="一级类别">
              <template #default="{ row }">
                <vxe-input v-model="row.categoryName1" disabled />
              </template>
            </vxe-column>
            <vxe-column field="styleAndColAndSiz" title="Style/Color/Size" :edit-render="{}" width="300">
              <template #default="{ row }">
                <div style="display: flex">
                  <div>
                    <el-select v-model="row.styleId" clearable filterable transfer :disabled="type==='view'" @change="styleSelectChange(row)">
                      <el-option v-for="item in styleOptions" :key="item.id" :label="item.styleName" :value="item.id" />
                    </el-select>
                  </div>
                  <div>
                    <el-select v-model="row.color" clearable filterable transfer :disabled="type==='view'" @change="_getSkuInfo(row)">
                      <el-option v-for="item in row.colorOptions" :key="item.id" :label="item.colorName" :value="item.colorName" />
                    </el-select>
                  </div>
                  <div>
                    <el-select v-model="row.size" clearable filterable transfer :disabled="type==='view'" @change="_getSkuInfo(row)">
                      <el-option v-for="item in row.sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                    </el-select>
                  </div>
                </div>
              </template>
              <template #edit="{ row }">
                <div style="display: flex">
                  <div>
                    <el-select v-model="row.styleId" clearable filterable transfer :disabled="type==='view'" @change="styleSelectChange(row)">
                      <el-option v-for="item in styleOptions" :key="item.id" :label="item.styleName" :value="item.id" />
                    </el-select>
                  </div>
                  <div>
                    <el-select v-model="row.color" clearable filterable transfer :disabled="type==='view'" @change="_getSkuInfo(row)">
                      <el-option v-for="item in row.colorOptions" :key="item.id" :label="item.colorName" :value="item.colorName" />
                    </el-select>
                  </div>
                  <div>
                    <el-select v-model="row.size" clearable filterable transfer :disabled="type==='view'" @change="_getSkuInfo(row)">
                      <el-option v-for="item in row.sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                    </el-select>
                  </div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="sku" title="sku" :edit-render="{autofocus: '.vxe-input--inner'}" width="160">
              <template #default="{ row }">
                <vxe-select v-model="row.sku" clearable filterable transfer :disabled="!row.styleId ||!row.color||!row.size||type ==='view'||row.skuOptions.length===1">
                  <vxe-option v-for="item in row.skuOptions" :key="item" :label="item" :value="item" />
                </vxe-select>
              </template>
              <template #edit="{ row }">
                <vxe-select v-model="row.sku" clearable filterable transfer :disabled="!row.styleId ||!row.color||!row.size||type==='view'||row.skuOptions.length===1">
                  <vxe-option v-for="item in row.skuOptions" :key="item" :label="item" :value="item" />
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column field="fnsku" title="Fnsku" :edit-render="{autofocus: '.vxe-input--inner'}">
              <template #edit="{ row }">
                <vxe-input v-model="row.fnsku" type="text" maxlength="50" :disabled="type==='view'" />
              </template>
            </vxe-column>
            <vxe-column field="upc" title="UPC" :edit-render="{autofocus: '.vxe-input--inner'}">
              <template #edit="{ row }">
                <vxe-input v-model="row.upc" type="text" maxlength="50" :disabled="type==='view'" />
              </template>
            </vxe-column>
            <vxe-column field="quantity" title="数量" :edit-render="{autofocus: '.vxe-input--inner'}">
              <template #edit="{ row }">
                <vxe-input v-model="row.quantity" type="integer" min="1" max="999999" :disabled="type==='view'" />
              </template>
            </vxe-column>
            <vxe-column field="boxCount" title="箱数" :edit-render="{autofocus: '.vxe-input--inner'}">
              <template #edit="{ row }">
                <vxe-input v-model="row.boxCount" type="integer" min="1" max="999999" :disabled="type==='view'" />
              </template>
            </vxe-column>
            <vxe-column field="weight" title="重量(kg)" :edit-render="{autofocus: '.vxe-input--inner'}">
              <template #edit="{ row }">
                <vxe-input v-model="row.weight" type="float" min="0.01" max="999999" :disabled="type==='view'" />
              </template>
            </vxe-column>
            <vxe-column field="volume" title="体积(m³)" :edit-render="{autofocus: '.vxe-input--inner'}">
              <template #edit="{ row }">
                <vxe-input v-model="row.volume" type="float" min="0.01" max="999999" :disabled="type==='view'" />
              </template>
            </vxe-column>
            <vxe-column v-if="type!=='view'" field="operate" title="操作">
              <template slot-scope="scope">
                <i class="el-icon-circle-plus-outline" style="margin-right:10px;" @click="insertEvent()" />
                <i class="el-icon-remove-outline" @click="removeEvent(scope)" />
              </template>
            </vxe-column>
          </vxe-table>
        </div>
        <el-row v-else style="margin-top:50px">
          <div style="display:flex;flex-direction: column;align-items: center;" @click="add">
            <i class="el-icon-circle-plus-outline" style="font-size:100px; color:#999" />
            <div>添加明细</div>
          </div>
        </el-row>
      </template>
    </el-skeleton>
    <el-row class="grid-content bg-blue" type="flex" justify="center" style="margin-top:50px">
      <el-button @click="goBack()">返回</el-button>
      <el-button v-if="type!=='view'" :loading="submitLoading" type="primary" @click="toSubmit()">提交</el-button>
    </el-row>
    <!-- 修改提交 -->
    <SubmitEdit v-model="editVisible" :edit-params="editParams" @goBack="goBack" />
  </div>
</template>
<script>
import { queryColorList, querySizeList } from '@/api/scm-api'
import { insertAppointOrder, getNewVendorList, getImsWarehouse,
  getwarehouseAddressList, getVendorAddressDetailList, getReturnPoCodeList,
  getPoCodeList, getAppointOrderinfo, editAppointOrderinfo, editAppointOrder,
  getCategory1List, getSkuInfo, queryStyleList } from '@/api/appointTransport'
import SubmitEdit from '../components/SubmitEdit.vue'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import NP from 'number-precision'
import dayjs from 'dayjs'

export default {
  components: { SubmitEdit },
  mixins: [commodityInfoDict],
  data() {
    return {
      loading: false,
      submitLoading: false,
      supplierform: {
        appointOrderCode: '',
        relationCode: '',
        stateDict: '',
        originPlaceId: '',
        destinationId: '',
        originPlaceAddress: '',
        destinationAddress: '',
        pickupContactNumber: '',
        pickupContactPerson: '',
        vendorId: '',
        vendorName: '',
        deliveryMethodDict: '',
        remark: '',
        estimateDeliveryTime: '',
        dilatTime: '',
        dilatDate: '',
        transportTypeDict: ''
      },
      detailDatas: [],
      vendorOptions: [],
      warehouseOptions: [],
      originOptions: [],
      originAddressOptions: [],
      destinationOptions: [],
      destinationAddressOptions: [],
      codeOptions: [],
      styleOptions: [],
      defaultRules: { required: true, message: this.$t('page.required'), trigger: 'change' },
      detailRules: { volume: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        vendorId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      },
      detailVendorId: '',
      warehouseCode: '',
      totalQuantity: 0,
      totalBoxCount: 0,
      totalVolume: 0.00,
      totalWeight: 0.00,
      type: '',
      editVisible: false,
      editParams: {},
      addParams: {},
      editLoading: false,
      editForm: { modifyReason: '' },
      stateDict: '',
      selectOptionWidth: ''
    }
  },
  computed: {
    // 校验存在一行退货单号，则退货单号列必填
    isSourceCode() {
      const arr = this.detailDatas.filter(item => item.sourceCode)
      return arr.length !== 0 && arr.length !== this.detailDatas.length
    },
    isReturn() {
      return this.supplierform.transportTypeDict === 'PURCHASE_RETURN'
    },
    isCollect() {
      return this.supplierform.transportTypeDict === 'PURCHASE_COLLECT'
    },
    orginAddParams() {
      const { originPlaceAddress } = this.supplierform
      const param = this.originAddressOptions?.find(item => item.address === originPlaceAddress) || {}
      return Object.assign(param, { locationType: 'ORIGIN_PLACE' })
    },
    destinationAddParams() {
      const { destinationAddress } = this.supplierform
      const param = this.destinationAddressOptions?.find(item => item.address === destinationAddress) || {}
      return Object.assign(param, { locationType: 'DESTINATION' })
    }
  },
  async mounted() {
    this._queryStyleList()
    await this._queryVendorList()
    await this._getImsWarehouse()
    const { stateDict, type } = this.$route.query
    this.type = type
    this.stateDict = stateDict
    if (this.type !== 'add') {
      this.getInfo(this.type)
    } else {
      this.supplierform = Object.assign(this.supplierform, { transportTypeDict: 'PURCHASE_COLLECT' })
      this.getTransType('PURCHASE_COLLECT')
    }
  },
  methods: {
    // 处理时间
    handleTime(fieldList, time) {
      if (!fieldList || !time) return
      const datetime = dayjs(time)
      // 格式化日期和时间
      const result_date = `${datetime.format('YYYY-MM-DD')}`
      const result_time = `${datetime.format('HH:mm')}`
      const arr = [result_date, result_time]
      fieldList.map((item, index) => {
        this.$set(this.supplierform, fieldList[index], arr[index])
      })
    },
    // 处理供应商id,仓库编号
    handleVendorAndWarehouse() {
      if (this.isCollect) {
        this.detailVendorId = this.supplierform.originPlaceId
        this.warehouseCode = this.supplierform.destinationId
      }
      if (this.isReturn) {
        this.detailVendorId = this.supplierform.destinationId
        this.warehouseCode = this.supplierform.originPlaceId
      }
    },
    async getInfo(type) {
      try {
        this.loading = true
        const { datas } = type === 'view'
          ? await getAppointOrderinfo(this.$route.query.id)
          : await editAppointOrderinfo(this.$route.query.id)
        this.supplierform = datas
        this.supplierform.deliveryMethodDict = datas.deliveryMethodDict?.toString()
        this.handleTime(['deliveryDate', 'deliveryTime'], datas.estimateDeliveryTime)
        this.handleTime(['dilatDate', 'dilatTime'], datas.estimateArrivalTime)
        this.handleDatas(datas)
        if (type === 'view') {
          this.detailDatas = datas.detailList
        } else {
          await this.editInfo(datas)
        }
      } finally {
        this.loading = false
      }
    },
    // 编辑页面,处理明细数据
    async editInfo(datas) {
      this.detailDatas = await Promise.all(datas.detailList.map(item => {
        return (async() => {
          if (item.styleId) {
            const data = this.styleOptions.find(i => i.id === item.styleId)
            const { datas: colorOptions } = await queryColorList({ styleId: data.id })
            const { datas: sizeOptions } = await querySizeList({ styleId: data.id })
            const { datas } = await getSkuInfo([{ styleName: data.styleName, colorName: item.color, sizeName: item.size }])
            return Object.assign(item, { sizeOptions, colorOptions, skuOptions: datas?.map(item => item.sku) })
          } else return item
        })()
      }))
      this.detailVendorId = datas.vendorId
      this.warehouseCode = datas.warehouseCode
    },
    async handleDatas(datas) {
      if (this.isCollect) {
        this.supplierform.originPlaceId = Number(datas.originPlaceId)
        this.originOptions = this.vendorOptions
        this.destinationOptions = this.warehouseOptions
        this.codeOptions = await this._getPoCodeList(this.supplierform.originPlaceId)
        this.originAddressOptions = await this._getVendorAddressList(this.supplierform.originPlaceId, [3])
        this.destinationAddressOptions = await this._getwarehouseAddressList(this.supplierform.destinationId)
      }
      if (this.isReturn) {
        this.supplierform.destinationId = Number(datas.destinationId)
        this.originOptions = this.warehouseOptions
        this.destinationOptions = this.vendorOptions
        this.codeOptions = await this._getReturnPoCodeList(this.supplierform.destinationId)
        this.originAddressOptions = await this._getwarehouseAddressList(this.supplierform.originPlaceId)
        this.destinationAddressOptions = await this._getVendorAddressList(this.supplierform.destinationId, [4])
      }
    },
    getTransType(e) {
      Object.assign(this.supplierform, { originPlaceId: '', destinationId: '', originPlaceAddress: '', destinationAddress: '', pickupContactNumber: '', pickupContactPerson: '', deliveryMethodDict: '' })
      this.originAddressOptions = this.destinationOptions = []
      if (e === 'PURCHASE_RETURN') {
        this.originOptions = this.warehouseOptions
        this.destinationOptions = this.vendorOptions
      } else if (e === 'PURCHASE_COLLECT') {
        this.originOptions = this.vendorOptions
        this.destinationOptions = this.warehouseOptions
      }
    },
    async _getSkuInfo(row) {
      row.sku = ''
      if (row.color && row.size && row.styleId) {
        const styleName = this.styleOptions?.find(item => item.id === row.styleId)?.styleName
        const { datas } = await getSkuInfo([{ styleName, colorName: row.color, sizeName: row.size }])
        row.skuOptions = datas?.map(item => item.sku)
        row.sku = datas?.map(item => item.sku)?.[0]
      }
    },
    async handleOrigin(e) {
      const { originPlaceId } = this.supplierform
      if (this.isReturn) {
        this.originAddressOptions = await this._getwarehouseAddressList(e)
        this.supplierform.originPlaceAddress = this.originAddressOptions[0]?.address
      }
      if (this.isCollect) {
        this.supplierform.deliveryMethodDict = this.originOptions.find(item => item.id === originPlaceId)?.deliveryMethod?.toString()
        this.originAddressOptions = await this._getVendorAddressList(e, [3])
        this.supplierform.originPlaceAddress = this.originAddressOptions[0]?.address
        this.supplierform.originPlaceAddress && this.getOrgin(this.supplierform.originPlaceAddress)
        this.codeOptions = await this._getPoCodeList(e)
        this.clearSourceCode(this.supplierform.originPlaceId, this.supplierform.destinationId)
      }
    },
    async handleDestination(e) {
      const { destinationId } = this.supplierform
      if (this.isCollect) {
        this.destinationAddressOptions = await this._getwarehouseAddressList(e)
        Object.assign(this.supplierform, { destinationAddress: this.destinationAddressOptions[0]?.address })
      }
      if (this.isReturn) {
        this.supplierform.deliveryMethodDict = this.destinationOptions.find(item => item.id === destinationId)?.deliveryMethod?.toString()
        this.destinationAddressOptions = await this._getVendorAddressList(e, [4])
        Object.assign(this.supplierform, { destinationAddress: this.destinationAddressOptions[0]?.address })
        this.supplierform.destinationAddress && this.getDestination(this.supplierform.destinationAddress)
        this.codeOptions = await this._getReturnPoCodeList(e)
        this.clearSourceCode(this.supplierform.destinationId, this.supplierform.originPlaceId)
      }
    },
    // 切换供应商，采购订单号清空
    clearSourceCode(vendorId, warehouseCode) {
      this.detailVendorId = vendorId
      this.warehouseCode = warehouseCode
      this.detailDatas.map(item => Object.assign(item, { vendorId: this.detailVendorId, sourceCode: '' }))
    },
    getOrgin(e) {
      if (this.isCollect) {
        this.hanldeContactInfo(this.originAddressOptions, e)
      }
    },
    getDestination(e) {
      if (this.isReturn) {
        this.hanldeContactInfo(this.destinationAddressOptions, e)
      }
    },
    // 提货联系人相关信息
    hanldeContactInfo(options, e) {
      const datas = options.find(item => item.address === e)
      Object.assign(this.supplierform, { pickupContactPerson: datas?.addressPerson ?? '', pickupContactNumber: datas?.contactNumber ?? '' })
    },
    async add() {
      // 校验基础信息所有必填
      this.$refs.Form.validate(async(valid) => {
        if (!valid) return this.$message.warning('请检查必填项')
        this.handleVendorAndWarehouse()
        this.detailDatas = [{ quantity: '', boxCount: '', volume: '', weight: '', vendorId: this.detailVendorId, color: '', size: '', sku: '', categoryName1: '', sizeOptions: [], colorOptions: [], skuOptions: [], sourceCode: '', fnsku: '', upc: '' }]
      })
    },
    async insertEvent() {
      const $table = this.$refs.xTable
      const record = { quantity: '', boxCount: '', volume: '', weight: '', vendorId: this.detailVendorId, color: '', size: '', sku: '', categoryName1: '', sizeOptions: [], colorOptions: [], skuOptions: [], sourceCode: '', fnsku: '', upc: '' }
      await $table.insertAt(record, 0)
      this.detailDatas.unshift(record)
    },
    removeEvent(scope) {
      const $table = this.$refs.xTable
      this.$confirm('该操作将删除当前行，无法撤销，是否继续？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        $table.remove(scope.row)
        this.detailDatas.splice(scope.rowIndex, 1)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    sumNum(list, field) {
      let count = 0
      list.forEach(item => {
        count = NP.round(NP.plus(count, Number(item[field])), 2)
      })
      const fieldMap = {
        'quantity': 'totalQuantity',
        'boxCount': 'totalBoxCount',
        'volume': 'totalVolume',
        'weight': 'totalWeight'
      }
      if (fieldMap[field]) {
        this[fieldMap[field]] = count
      }
      return count
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['quantity', 'boxCount', 'weight', 'volume'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
        })
      ]
    },
    goBack() {
      this.$router.go(-1)
    },
    async _getImsWarehouse() {
      const { datas } = await getImsWarehouse({ areaType: 'INTERNAL', warehouseCategory: 1, warehouseType: 'OWN', status: 1 })
      this.warehouseOptions = datas?.map(item => Object.assign(item, { label: item.warehouseName, value: item.logicWarehouseCode }))
    },
    async _queryVendorList() {
      const { datas } = await getNewVendorList({ useStatus: 1 })
      this.vendorOptions = datas?.map(item => Object.assign(item, { label: item.vendorName, value: item.id }))
    },
    async _getwarehouseAddressList(id) {
      try {
        const { datas } = await getwarehouseAddressList(id)
        return datas
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async _getVendorAddressList(vendorId, addressTypeList) {
      try {
        const { datas } = await getVendorAddressDetailList({ vendorId, addressTypeList })
        return datas
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async _getReturnPoCodeList(id) {
      try {
        const { datas } = await getReturnPoCodeList(id)
        return datas
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async _getPoCodeList(id) {
      try {
        const { datas } = await getPoCodeList(id)
        return datas
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList({ styleStatus: 1 })
      this.styleOptions = datas
    },
    async styleSelectChange(row) {
      Object.assign(row, { color: '', size: '', sku: '', categoryId1: '', categoryName1: '', fnsku: '', upc: '', sizeOptions: [], colorOptions: [], skuOptions: [] })
      const data = this.styleOptions.find(item => item.id === row.styleId)
      row.styleName = data?.styleName
      if (row.styleId) {
        const { datas: colorDatas } = await queryColorList({ styleId: data?.id })
        const { datas: sizeDatas } = await querySizeList({ styleId: data?.id })
        const { datas: categoryDatas } = await getCategory1List([data?.styleName])
        Object.assign(row, { sizeOptions: sizeDatas, colorOptions: colorDatas, categoryName1: categoryDatas[0]?.categoryName1, categoryId1: categoryDatas[0]?.categoryId1 })
      }
    },
    toSubmit() {
      this.$refs.Form.validate(async(valid) => {
        if (valid) {
          if (this.detailDatas.length === 0) return this.$message.error('至少有一条预约运输明细')
          const $table = this.$refs.xTable
          const errMap = await $table.validate(true).catch(errMap => errMap)
          if (errMap) return this.$message.error('请检查必填项')
          if (this.isSourceCode) return this.$message.error('明细中存在至少一行数据有采购订单/采购退货单号，请完善所有明细的【采购订单号/采购退货单号】字段')
          if (this.type === 'add') {
            this.handleAdd()
          } else {
            this.handleEdit()
          }
        }
      })
    },
    handleSubmitParams() {
      const { totalBoxCount, totalQuantity, totalWeight, totalVolume, supplierform } = this
      const { originPlaceId, destinationId, deliveryDate, deliveryTime, dilatTime, dilatDate } = supplierform
      const originPlaceName = this.originOptions?.find(item => item.value === originPlaceId)?.label
      const destinationName = this.destinationOptions?.find(item => item.value === destinationId)?.label
      const vendorName = this.vendorOptions?.find(item => item.id === this.detailVendorId)?.vendorName
      const warehouseName = this.warehouseOptions?.find(item => item.logicWarehouseCode === this.warehouseCode)?.warehouseName
      const DeliveryTime = deliveryDate + ' ' + deliveryTime + ':00'
      const ArrivalTime = dilatDate + ' ' + dilatTime + ':00'
      this.detailDatas.map(item => Object.assign(item, { vendorName }))
      this.addParams = Object.assign(supplierform, { estimateDeliveryTime: DeliveryTime, estimateArrivalTime: ArrivalTime, detailList: this.detailDatas, vendorId: this.detailVendorId,
        warehouseCode: this.warehouseCode, addressList: [this.orginAddParams, this.destinationAddParams] },
      { totalBoxCount, totalQuantity, totalVolume, totalWeight, originPlaceName, destinationName, vendorName, warehouseName })
    },
    async handleAdd() {
      try {
        this.submitLoading = true
        this.handleSubmitParams()
        await insertAppointOrder(this.addParams)
        this.submitLoading = false
        this.goBack()
      } finally {
        this.submitLoading = false
      }
    },
    async handleEdit() {
      const { stateDict } = this.$route.query
      this.handleSubmitParams()
      if (['WAIT_PEND', 'CONFIRM'].includes(stateDict)) {
        this.editVisible = true
        this.editParams = this.addParams
      } else {
        try {
          this.submitLoading = true
          this.handleVendorAndWarehouse()
          await editAppointOrder(this.addParams)
          this.goBack()
          this.submitLoading = false
        } finally {
          this.submitLoading = false
        }
      }
    },
    setOptionWidth(event) {
      // 下拉框弹出时，设置弹框的宽度
      this.$nextTick(() => {
        this.selectOptionWidth = event.srcElement.offsetWidth + 'px'
      })
    }
  }
}
</script>
<style scoped>
.my-select .el-select-dropdown__item {
  overflow: visible;
  display: block;
}
</style>
