<template>
  <div>
    <el-dialog
      title="修改预约运输单"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="30%"
    >
      <div>当前预约单已确认运输，确认修改将发起审批流程，请填写修改原因。</div>
      <el-form ref="editForm" :model="editForm" label-width="150px">
        <el-form-item label="修改原因" label-width="120" prop="modifyReason" :rules="defaultRules">
          <el-input
            v-model="editForm.modifyReason"
            type="textarea"
            :rows="6"
            placeholder="请输入原因，不超过256字符"
            maxlength="256"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button :loading="editLoading" type="primary" @click="submitEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { editAppointOrder } from '@/api/appointTransport'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      editLoading: false,
      editForm: { modifyReason: '' },
      defaultRules: {
        modifyReason: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.editForm.modifyReason = ''
        this.$refs.editForm.resetFields()
      }
    }
  },
  methods: {
    submitEdit() {
      this.$refs.editForm.validate(async(valid) => {
        if (valid) {
          try {
            this.editLoading = true
            Object.assign(this.editParams, this.editForm)
            await editAppointOrder(this.editParams)
            this.editLoading = false
            this.$emit('goBack')
          } finally {
            this.editLoading = false
          }
        }
      })
    }
  }
}
</script>
